<template>
  <div class="entry">
    <Spinner :is-loading="uploadLoading" :message="loadingMessage" />
    <h1 />
    {{ $t('entry.title') }}
    <span style="font-size: 1em"
          class="date input-period"
    >({{ $t('frenchMonths')[currentClosureDate.month - 1] + ' ' + currentClosureDate.year.toString() }})</span>
    </h1>
    <div v-if="!disabledEntry" class="buttons buttons-align-right">
      <div class="extourne">
        <input id="checkbox" v-model="extourne" type="checkbox" style="pointer-events: none">
        <!--<input id="checkbox" v-model="extourne" type="checkbox" :disabled="selectedFamily.extourne === false">-->
        <label for="extourne">Réaliser une extourne</label>
      </div>
      <v-button class="flat" @click="selectFamily()"><v-svg>cancel</v-svg> {{ $t('cancel') }}</v-button>
      <v-button :disabled="!allIsValid || isSaving" @click="saveEntries()"><v-svg>save</v-svg> {{ $t('save') }}</v-button>
    </div>

    <div v-else class="buttons buttons-align-right">
      <input id="file-upload"
             ref="file"
             type="file"
             accept=".csv"
             class="input-file"
             :disabled="lockedEntry"
             @change="checkIfAlreadyUploaded"
      >

      <v-button
        v-if="selectedFormat && !['MAG_SUPER', 'RPO_SUPER', 'SUPER', 'HYPER','INVENTAIRE_SUPER'].includes(selectedFormat)"
        :disabled="lockedEntry"
        @click="onPickFile"
      >
        <v-svg>import</v-svg><span>{{ $t('button.upload') }}</span>
      </v-button>
    </div>

    <div class="content" :class="{ 'family-selected': !disabledEntry }">
      <section class="family-choice">
        <template v-if="loading">
          <loading />
        </template>
        <template v-else>
          <div class="select-family">
            <div>
              <h2 v-if="!selectedFormat">{{ $t('entry.familyChoice') }}</h2>
              <h2 v-else>{{ selectedFormat }}</h2>
              <select v-if="defaultFormat.length === 0 || defaultFormat.length > 1" v-model="selectedFormat">
                <option disabled value="">{{ $t('families.selectFormat') }}</option>
                <option v-for="(value, name) in formattedFamilies" :key="name" :value="name">{{ name }}</option>
              </select>
            </div>
          </div>
          <div v-if="lockedEntry" class="locked-entry"> {{ $t('entry.messageLockedEntry') }} {{ formatToLock.openingDay }} du mois </div>
          <div v-if="!lockedEntry && ['MAG_SUPER'].includes(selectedFormat)" class="families">
            <router-link class="link-MAG_SUPER" :to="{ name: 'demarque', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.EnteringMarkdowns') }}</div>
            </router-link>
            <router-link class="link-MAG_SUPER" :to="{ name: 'entry-demarque-stk-trad', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.EntryStocksTradTtc') }}</div>
            </router-link>
          </div>
          <div v-if="!lockedEntry && ['RPO_SUPER'].includes(selectedFormat)" class="families">
            <router-link class="link-MAG_SUPER" :to="{ name: 'demarque', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.EnteringMarkdowns') }}</div>
            </router-link>
            <router-link class="link-MAG_SUPER" :to="{ name: 'entry-demarque-stk-trad', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.EntryStocksTradTtc') }}</div>
            </router-link>
          </div>
          <div v-if="!lockedEntry && selectedFormat === 'INVENTAIRE_SUPER'" class="families">
            <router-link class="link-INVENTAIRE_SUPER" :to="{ name: 'demarque-invent', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.EnteringInventories') }}</div>
            </router-link>
            <router-link class="link-INVENTAIRE_SUPER" :to="{ name: 'demarque-invent-all', query: { format: selectedFormat } }">
              <div class="description">{{ $t('entry.InventoriesForAll') }}</div>
            </router-link>
          </div>
          <div v-if="!lockedEntry && selectedFormat === 'RELAIS_CLOTURE_SUPER'" class="families">
            <router-link
              class="link-RELAIS_CLOTURE_SUPER"
              :to="{
                name: 'entry-demarque-frais-trad',
                params: { rubriqueData: getRubriqueDataFromfamily('M_PRVAAPpa') },
                query: { format: selectedFormat }
              }"
            >
              <div class="description">{{ $t('entry.EntryProvisionsAAPTrad') }}</div>
            </router-link>
          </div>
          <div
            v-if="!lockedEntry && !['MAG_SUPER', 'RPO_SUPER', 'INVENTAIRE_SUPER', 'RELAIS_CLOTURE_SUPER', 'SUPER'].includes(selectedFormat)"
            class="families"
          >
            <button
              v-for="(family, index) in formattedFamilies[selectedFormat]"
              :key="index"
              :class="getClass(family.code, 'STOCKSUPP')"
              @click="selectFamily(family)"
            >
              <div class="code">{{ family.code }}</div>
              <div class="description">{{ family.description }}</div>
            </button>
          </div>
          <div
            v-if="!lockedEntry && ['MAG_SUPER', 'RPO_SUPER', 'INVENTAIRE_SUPER', 'RELAIS_CLOTURE_SUPER', 'SUPER'].includes(selectedFormat)"
            class="families"
          >
            <div v-for="(family, index) in formattedFamilies[selectedFormat]" :key="index">
              <button v-if="family.account == null" :class="getClass(family.code, 'STOCKSUPP')" @click="selectFamily(family)">
                <div class="code">{{ family.code }}</div>
                <div class="description">{{ family.description }}</div>
              </button>
            </div>
          </div>
        </template>
      </section>

      <section class="entries" :class="{ disabled: disabledEntry }">
        <h2>{{ $t('entry.entry') }}</h2>
        <div class="details">
          <div>
            <span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}
          </div>
          <div>
            <span class="label">{{ $t('user') }}:</span> {{ userCode }}
          </div>
          <div>
            <span class="label">{{ $t('family') }}:</span>
            {{ !disabledEntry ? `${selectedFamily.code} : ${selectedFamily.description}` : `Vous n'avez pas encore selectionné de famille` }}
          </div>
          <div>
            <span class="label">{{ $t('entry.totalAmount') }} </span> {{ amountTotal }}
          </div>
          <div v-if="!allIsValid" class="error-message">
            {{ $t('entry.error') }}
            <span v-if="isAmountZero && amountTotal > 0">
              <br>
              {{ $t('entry.errorAmount') }}
            </span>
          </div>
        </div>
        <div v-if="entries.length" class="grid">
          <div />
          <div v-for="name in Object.keys(this.entries[0])" :key="`header#${name}`" class="header cell">{{ $t(name) }}</div>
          <div />
          <template v-for="(vEntry, i) in $v.entries.$each.$iter">
            <div :key="`delete#${i}`" class="cell">
              <v-button tabindex="-1" class="flat" @click="removeLine(i)"><v-svg>cancel</v-svg></v-button>
            </div>
            <div :key="`uc#${i}`" class="cell">
              <input
                :ref="'input_uc_' + i"
                v-model="vEntry.$model.accountingUnit"
                v-tooltip.bottom-start="vEntry.accountingUnit.$invalid ? 'UC non valide' : ''"
                :list="`input-uc#${i}`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.accountingUnit.$invalid }"
                placeholder="ex: FRH002A"
                type="text"
                @click="activeLine = i"
              >
              <datalist :id="`input-uc#${i}`">
                <option v-for="uc in validAccountingUnits(vEntry.$model.siteId)" :key="`option-uc#${i}:${uc}`" :value="uc">
                  {{ uc }}
                </option>
              </datalist>
            </div>
            <div :key="`site#${i}`" class="cell">
              <input
                v-model="vEntry.$model.siteId"
                v-tooltip.bottom-start="vEntry.siteId.$invalid ? 'Site non valide' : ''"
                :list="`sites`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.siteId.$invalid }"
                placeholder="ex: 0002"
                type="text"
                @focus="fillDataListIfEmpty('sites')"
                @input="completeDataList($event, 'sites')"
                @click="activeLine = i"
              >
              <datalist :id="`sites`" />
            </div>

            <div :key="`cr#${i}`" class="cell">
              <input
                v-model="vEntry.$model.resultCenterId"
                v-tooltip.bottom-start="vEntry.resultCenterId.$invalid ? 'CR non valide' : ''"
                :list="`crs`"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.resultCenterId.$invalid }"
                placeholder="ex: 0002"
                type="number"
                @focus="fillDataListIfEmpty('crs')"
                @input="completeDataList($event, 'crs')"
                @click="activeLine = i"
              >
              <datalist :id="`crs`">
                <!-- <option
                  v-for="cr in validResultCenterIds()"
                  :key="`option-cr#${i}:${cr}`"
                  :value="cr">
                  {{ cr }}
                </option> -->
              </datalist>
            </div>

            <div :key="`crlabel#${i}`" class="cell">
              <span>
                {{ getResultCenterLabel(vEntry.$model.resultCenterId) }}
              </span>
            </div>

            <div :key="`amount#${i}`" class="cell">
              <input
                v-model="vEntry.$model.amount"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.amount.$invalid }"
                placeholder="ex: -222"
                type="number"
                step="0.001"
                @blur="showModalIfAboveThreshold(vEntry.$model.amount)"
                @click="activeLine = i"
              >
            </div>

            <div :key="`label#${i}`" class="cell">
              <input
                v-model="vEntry.$model.label"
                maxlength="40"
                :disabled="disabledEntry"
                :class="{ 'active-line': activeLine === i, error: vEntry.label.$invalid }"
                placeholder="ex: Écart entre Pilot et Hyperion"
                type="text"
                @click="activeLine = i"
              >
            </div>
            <div v-if="selectedFamily" class="cell">
              <v-button class="flat" @click="newLine"><v-svg>plus</v-svg></v-button>
            </div>
          </template>
        </div>
        <!-- <div class="buttons">
          <v-button v-if="selectedFamily" class="new-line" :disabled="disabledEntry" @click="newLine"> <v-svg>plus</v-svg> {{ $t('entry.newLine') }}</v-button>
        </div> -->
      </section>
    </div>

    <v-modal v-if="showModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ warningMessage }} </span>
      <v-button slot="footer" class="small" @click="showModal = false">{{ $t('entry.modal.continue') }}</v-button>
    </v-modal>

    <v-modal v-if="showConfirmModal">
      <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
      <span slot="body">{{ $t('entry.modal.fileMessage') }} </span>

      <v-button slot="footer" class="small" @click="closeConfirmModal">{{ $t('close') }}</v-button>
    </v-modal>

    <v-modal v-if="showErrorModal">
      <h3 slot="header">Erreur lors de l'enregistrement du fichier</h3>
      <div slot="body" style="max-height: 400px; overflow-x: scroll">
        <div v-html="errorMessages.join('')" />
      </div>
      <v-button slot="footer" class="small" @click="showErrorModal = false">{{ $t('close') }}</v-button>
    </v-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import { blockEntry, formatAmount, pad } from '../utils/utils'
  import { DateTime } from 'luxon'
  import { importCsv } from '@/utils/csv'
  import Spinner from '@/components/base/Spinner'
  import moment from 'moment'
  import { JANUARY, JUNE, DECEMBER, SUPP12 } from '@/utils/CONF'

  const emptyLine = { accountingUnit: '', siteId: '', resultCenterId: '', resultCenterLabel: '', amount: '', label: '' }

  export default {
    name: 'Entry',
    components: {
      Spinner
    },
    props: {
      formatToEmit: String
    },
    data() {
      return {
        isSaving: false,
        loading: false,
        uploadLoading: false,
        loadingMessage: '',
        selectedFamily: undefined,
        date: DateTime.local(),
        entries: [],
        activeLine: 0,
        userCode: this.$userInfos.uid,
        file: null,
        showModal: false,
        showConfirmModal: false,
        showErrorModal: false,
        selectedFormat: '',
        defaultFormat: [],
        haveToDelete: false,
        today: new Date(),
        warningMessage: '',
        extourne: false,
        formatToLock: '',
        errorMessages: []
      }
    },
    computed: {
      ...mapGetters('configuration', ['currentClosureDate', 'formattedFamilies', 'lastClosureDate']),
      ...mapState('configuration', ['habilitations', 'families', 'validIndicators', 'formats', 'alertThreshold', 'format']),
      lockedEntry() {
        this.formatToLock = this.format.find((f) => f.formatType === this.selectedFormat)
        return blockEntry(
        this.currentClosureDate.DataAnalytiqueclosureEndDate.seconds,
        this.lastClosureDate.DataAnalytiqueclosureEndDate.seconds,
        this.formatToLock
        )
      },
      disabledEntry() {
        return !this.selectedFamily
      },
      fileName() {
        return this.file && this.file.name.split('.')[0]
      },
      imputationDate() {
        return this.currentClosureDate.year.toString() + this.currentClosureDate.month.toString().padStart(2, '0')
      },
      nextImputationDate() {
        let month, year
        if(this.currentClosureDate.month === DECEMBER) {
            month = 1
            year = this.currentClosureDate.year + 1
        }else if(this.currentClosureDate.month === JUNE){
          month = this.currentClosureDate.month + 2
          year = this.currentClosureDate.year
        } else{
          month = this.currentClosureDate.month === SUPP12 ? JANUARY : this.currentClosureDate.month + 1
          year = this.currentClosureDate.month === SUPP12 ? this.currentClosureDate.year + 1 : this.currentClosureDate.year
        }

        return year.toString() + month.toString().padStart(2, '0')
      },
      entriesForStore() {
        return this.entries.reduce((acc, entry) => {
          acc.push({
            accountingUnit: entry.accountingUnit,
            format: this.selectedFormat.toUpperCase(),
            site: entry.siteId,
            resultCenter: parseInt(entry.resultCenterId),
            label: entry.label,
            amount: entry.amount ? parseFloat(entry.amount) : 0,
            inputDate: this.today,
            imputationDate: this.imputationDate,
            family: this.selectedFamily.code,
            familyAccount: this.selectedFamily.account,
            familyRubric: this.selectedFamily.rubrique,
            user: this.userCode,
            entryType: this.$t('manual')
          })
          return acc
        }, [])
      },
      entriesExtourne() {
        return  this.extourne ? this.entriesForStore.map(a => {
          return {
            ...a,
            amount: -a.amount,
            entryType: this.$t('entry.extourne'),
            imputationDate: this.nextImputationDate
          }
        }) : []
      },
      amountTotal() {
        return Math.round(this.entries.map((e) => (e.amount ? parseFloat(e.amount) : 0)).reduce((a, b) => a + b, 0))
      },
      isAmountZero() {
        return this.selectedFamily && this.selectedFamily.isAmountZero
      },
      allIsValid() {
        if (this.isAmountZero) {
          return !this.$v.entries.$invalid && this.amountTotal === 0
        } else {
          return !this.$v.entries.$invalid
        }
      }
    },
    watch: {
      selectedFamily(val) {
        if (val && this.validAccountingUnits('').length === 1) {
          this.$v.entries.$each.$iter[0].$model.accountingUnit = this.validAccountingUnits('')[0]
        }

        if (val && this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit).length === 1) {
          this.$v.entries.$each.$iter[0].$model.siteId = this.validSiteIds(this.$v.entries.$each.$iter[0].$model.accountingUnit)[0]
        }

        this.extourne = val && val.extourne === false ? false : true
      },
      entries() {
        this.checkLockingDateAndRedirect()
      },
      formatToLock() {
        this.checkLockingDateAndRedirect()
      }
    },
    mounted() {
      this.defaultFormat = this.habilitations.formatType
      this.selectedFormat = this.defaultFormat.length === 1 ? this.defaultFormat[0].toUpperCase() : this.$route.params.selectedFormat ?? ''
    },
    methods: {
      ...mapActions('entries', ['insertEntries', 'deleteAndInsertEntries', 'fetchEntriesByType']),
      completeDataList(e, id) {
        const dlOptions =
          id === 'sites'
            ? this.validSiteIds(this.$v.entries.$each.$iter[0].accountingUnit.$model).map((o) => {
                return [`<option value="${o}">${this.getSiteLabel(o)}</option>`, o.toLowerCase()]
              })
            : this.validResultCenterIds(this.$v.entries.$each.$iter[0].siteId.$model).map((o) => {
                return [`<option value="${o}">${this.getResultCenterLabel(o)}</option>`, o.toLowerCase()]
              })

        const fill = (val) => (document.getElementById(id).innerHTML = val)

        if (!e.target.value) {
          fill(dlOptions.reduce((sum, [html]) => sum + html, ''))
        } else if (!(e instanceof InputEvent)) {
          // OR: else if(!e.inputType)
          e.target.blur()
        } else {
          const inputValue = e.target.value.toLowerCase()
          let result = ''
          for (const [html, valuePattern] of dlOptions) {
            if (!valuePattern.indexOf(inputValue)) {
              result += html
            } else if (result) {
              break
            }
          }
          fill(result)
        }
      },
      getRubriqueDataFromfamily(rubrique) {
        const rubriqueData = this.formattedFamilies[this.selectedFormat].filter((data) => data.code === rubrique)
        return rubriqueData[0]
      },
      fillDataListIfEmpty(id) {
        if (!document.getElementById(id).innerHTML) {
          this.completeDataList({ target: {} }, id)
        }
      },
      validAccountingUnits(siteId) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites.filter((UCSites) => !siteId.length || siteId === UCSites.SITE_ORACLE).map(
              (UCSites) => UCSites.UC_ORACLE
            )
          )
        ].sort()
      },
      validSiteIds(accountingUnit) {
        return [
          ...new Set(
            this.validIndicators[this.selectedFormat].UCSites.filter(
              (UCSites) =>
                (!accountingUnit.length || accountingUnit === UCSites.UC_ORACLE) &&
                (!this.habilitations.site.length || this.habilitations.site.includes(UCSites.SITE_ORACLE))
            ).map((UCSites) => UCSites.SITE_ORACLE)
          )
        ].sort()
      },
      validResultCenterIds() {
        if (this.selectedFamily.resultCenters != null) {
          return [...new Set(this.selectedFamily.resultCenters.split(',').map((CR) => CR.toString()))]
            .map((uc) => (uc.length === 4 ? '0' + uc : uc))
            .sort()
        } else {
          return [...new Set(this.validIndicators[this.selectedFormat].CRs.map((CRS) => CRS.CR.toString()))]
            .map((uc) => (uc.length === 4 ? '0' + uc : uc))
            .sort()
        }
      },
      getResultCenterLabel(resultCenterID) {
        return resultCenterID && this.validResultCenterIds().includes(resultCenterID)
          ? this.validIndicators[this.selectedFormat].CRs.find((CR) => CR.CR.toString() === resultCenterID).LibelleCR
          : ''
      },
      getSiteLabel(siteID) {
        return this.validIndicators[this.selectedFormat].UCSites.find((UCSites) => UCSites.SITE_ORACLE === siteID).siteLabel ?? ''
      },
      selectFamily(family) {
        this.selectedFamily = family
        if (!family) this.entries = []
        else this.newLine()
      },
      newLine() {
        const lastLine = this.entries[this.entries.length - 1]
        this.entries.push(
          lastLine
            ? {
                ...emptyLine,
                accountingUnit: lastLine.accountingUnit,
                siteId: lastLine.siteId,
                label: this.selectedFamily.description
              }
            : { ...emptyLine, label: this.selectedFamily.description }
        )
        this.activeLine = this.entries.length - 1
        this.$nextTick(() => {
          this.$refs['input_uc_' + this.activeLine][0].focus()
        })
      },
      removeLine(i) {
        this.entries.splice(i, 1)
        if (!this.entries.length) {
          this.entries = [{ ...emptyLine }]
        }
      },
      saveEntries() {
        this.isSaving = true
        this.insertEntries(this.entriesForStore.concat(this.entriesExtourne)).then(() => {
          this.selectFamily()
          this.isSaving = false
        })
      },
      showModalIfAboveThreshold(amount) {
        if (Math.abs(amount) >= this.alertThreshold) {
          this.showModal = true
          this.warningMessage =
            this.$t('entry.modal.alertThresholdMessage') +
            new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Number(this.alertThreshold))
        }
      },
      onPickFile() {
        this.$refs.file.click()
      },
      closeConfirmModal() {
        this.showConfirmModal = false
        this.uploadLoading = false
      },
      async checkIfAlreadyUploaded(e) {
        this.uploadLoading = true
        this.loadingMessage = 'Vérification du fichier...'
        this.file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0]
        const alreadyUploaded = await this.fetchEntriesByType(this.fileName)
        if (alreadyUploaded) this.showConfirmModal = true
        else this.upload(false)
        e.target.value = ''
      },
      upload(haveToDelete) {
        this.closeConfirmModal()
        this.haveToDelete = haveToDelete
        const reader = new FileReader()
        reader.onload = (r) => {
          const formattedCSV = importCsv(r.target.result)
          if (
            formattedCSV[0].CodeFamilleSEM &&
            formattedCSV[0].Libelle &&
            formattedCSV[0].UC &&
            formattedCSV[0].Site &&
            formattedCSV[0].CR &&
            formattedCSV[0].Montant &&
            formattedCSV[0].Format
          ) {
            this.checkFile(formattedCSV, this.fileName)
          } else {
            Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.$t('restitution.error.file')}`)
          }
        }
        reader.readAsText(this.file)
      },
      async checkFile(entries, fileName) {
        this.errorMessages = []
        let allAmountZero = {}
        this.uploadLoading = true
        let formatsUpper = this.formats.map((format) => format.toUpperCase())
        entries.forEach((entry, index) => {
          let UCSiteOk = true
          let CROk = true
          if (this.checkProperties(entry)) {
            entry.Montant = formatAmount(entry.Montant)
            const formatOk = formatsUpper.includes(entry.Format.toUpperCase()) && this.selectedFormat.toUpperCase() === entry.Format.toUpperCase()
            const familyOk = this.families.some(
              (f) => f.code.normalize() === entry.CodeFamilleSEM.normalize() && entry.Format.toUpperCase() === f.formatType.toUpperCase()
            )
            if (formatOk) {
              UCSiteOk = this.validIndicators[entry.Format.toUpperCase()].UCSites.some(
                (UCSite) => entry.UC === UCSite.UC_ORACLE && this.removeLeadingZeros(entry.Site) === this.removeLeadingZeros(UCSite.SITE_ORACLE)
              )
              CROk = this.validIndicators[entry.Format.toUpperCase()].CRs.some((CR) => entry.CR === CR.CR.toString())
            }
            const amountOk = !isNaN(entry.Montant)
            const isAmountZero = this.families.find(
              (f) =>
                f.code.normalize() === entry.CodeFamilleSEM.normalize() &&
                f.formatType.normalize() === entry.Format.normalize() &&
                f.isAmountZero === true
            )
            if (amountOk && isAmountZero) {
              allAmountZero[isAmountZero.code.normalize()] =
                allAmountZero && allAmountZero[isAmountZero.code.normalize()]
                  ? allAmountZero[isAmountZero.code.normalize()] + entry.Montant
                  : entry.Montant
            }
            if (!formatOk) this.errorMessages.push(this.$t('restitution.error.format', { line: index + 1 }))
            if (!familyOk) this.errorMessages.push(this.$t('restitution.error.family', { line: index + 1 }))
            if (!UCSiteOk) this.errorMessages.push(this.$t('restitution.error.UCSite', { line: index + 1 }))
            if (!CROk) this.errorMessages.push(this.$t('restitution.error.CR', { line: index + 1 }))
            if (!amountOk) this.errorMessages.push(this.$t('restitution.error.amount', { line: index + 1 }))
          } else {
            this.errorMessages.push(this.$t('restitution.error.lineInError', { line: index + 1 }))
          }
        })
        const amountZeroOk = []
        this.loadingMessage = 'Importation du fichier...'
        for (const [key, value] of Object.entries(allAmountZero)) {
          if (Math.round(Math.abs(value)) !== 0) amountZeroOk.push(key)
        }

        if (amountZeroOk.length)
          this.errorMessages.push('Les familles suivantes doivent avoir la somme des montants égal à zéro : ' + amountZeroOk.join(', '))

        if (this.errorMessages.length) {
          this.showErrorModal = true
          //Vue.toasted.error(`Erreur lors de l'enregistrement du fichier</br> ${this.errorMessages.join()}`)
        } else {
          this.loadingMessage = 'Sauvegarde du fichier...'
          const formattedEntries = entries.map((e) => {
            ``
            return {
              accountingUnit: e.UC,
              format: e.Format.toUpperCase(),
              site: this.validIndicators[e.Format.toUpperCase()].UCSites.find((UCSite) => e.UC === UCSite.UC_ORACLE && this.removeLeadingZeros(e.Site) === this.removeLeadingZeros(UCSite.SITE_ORACLE)).SITE_ORACLE,
              resultCenter: parseInt(e.CR),
              label: e.Libelle,
              amount: parseFloat(e.Montant),
              inputDate: this.today,
              imputationDate: this.imputationDate,
              family: e.CodeFamilleSEM,
              familyAccount: this.families.find((f) => f.code === e.CodeFamilleSEM && f.formatType.toUpperCase() === e.Format.toUpperCase()).account,
              familyRubric: this.families.find((f) => f.code === e.CodeFamilleSEM && f.formatType.toUpperCase() === e.Format.toUpperCase()).rubrique,
              user: this.$userInfos.uid,
              entryType: fileName
            }
          })
          this.haveToDelete
            ? await this.deleteAndInsertEntries({
                entries: formattedEntries.concat(this.calculateExtourne(formattedEntries)),
                file_name: this.fileName
              })
            : await this.insertEntries(formattedEntries.concat(this.calculateExtourne(formattedEntries)))
        }
        this.uploadLoading = false
        this.file = null
      },
      removeLeadingZeros(str) {
        const regex = new RegExp('^0+(?!$)', 'g')
        str = str.replaceAll(regex, '')
        return str
      },
      calculateExtourne(entries) {
        let extourneEntries = []
        for(const entry of entries) {
          if(this.families.find(f => f.code === entry.family && f.formatType.toUpperCase() === entry.format.toUpperCase()).extourne) {
            extourneEntries.push(
              {
                ...entry,
                amount: -entry.amount,
                imputationDate: this.nextImputationDate
              }
            )
          }
        }
        return extourneEntries
      },
      getClass(code, motif) {
        return {
          family: !code.toUpperCase().startsWith(motif),
          'family-Stock': code.toUpperCase().startsWith(motif)
        }
      },
      changeFormat() {
        this.$emit('changeFormat')
      },
      checkLockingDateAndRedirect() {
        const today = moment()
        const closeDate = new Date(this.currentClosureDate.DataAnalytiqueclosureEndDate.seconds * 1000)

        const closingHour = moment(moment(closeDate).format('yyyy-MM-dd') + ` ${this.formatToLock?.openingDay}:00:00`)
        const diffMinutes = moment(closingHour).diff(moment(), 'minutes')

        // Si la date de cloture est aujourd'hui et qu'un format est sélectionné et que l'heure de clôture est dans au plus 30min
        if (moment(closeDate).isSame(today, 'day') && this.formatToLock && diffMinutes <= 30 && diffMinutes >= 0) {
          clearInterval()
          setInterval(() => {
            if (this.lockedEntry) {
              window.alert(this.$t('entry.entryTimeToLockAlertMessage'))
              this.$router.push('/')
            }
          }, 30000)
        } else {
          clearInterval()
        }
      },
      checkProperties(obj) {
        for (let key in obj) {
          if (obj[key] !== null && obj[key] != '') return true
        }
        return false
      }
    },
    validations: {
      entries: {
        $each: {
          accountingUnit: {
            isValid: function(value, entry) {
              return this.validAccountingUnits(entry.siteId).includes(value)
            }
          },
          siteId: {
            isValid: function(value, entry) {
              return this.validSiteIds(entry.accountingUnit).includes(value)
            }
          },
          resultCenterId: {
            isValid: function(value) {
              return this.validResultCenterIds().includes(value)
            }
          },
          amount: {
            isValid: function(value, entry) {
              return !isNaN(Number(entry.amount))
            }
          },
          label: {
            required
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-file {
    display: none;
  }

  .select-family > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    h2 {
      flex: 1;
    }
    select {
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 5px 8px;
    }
    select:focus {
      outline: none;
    }
  }
  h2 {
    @extend %font-bold;
    font-size: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .buttons {
    position: absolute;
    display: flex;
    margin-bottom: 4rem;

    &-align-right {
      flex-direction: row;
      top: 6.4rem;
      right: 6.4rem;
    }
  }
  .entry {
    overflow: hidden;
    position: relative;
    .content {
      display: flex;
      position: absolute;
      transition: 500ms;
      left: 6.4rem;
      &.family-selected {
        left: calc(-50vw + 6.4rem);
      }
    }
    .family-choice {
      width: 50vw;
      padding-right: 8rem;

      .families {
        max-height: calc(100vh - 21rem);
        overflow-y: scroll;

        button.family {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $white;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
        button.family-Stock {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 6.4rem;
          width: 98%;
          border-radius: 16px;
          margin-bottom: 2rem;
          background-color: $light-grey;
          outline: none;
          border: 1px solid $dark-grey;

          .code {
            @extend %font-bold;
            width: 20rem;
          }
        }
      }
    }
    .entries {
      max-height: calc(100vh - 13rem);
      width: calc(100vw - #{$sidebar-width});
      overflow-y: scroll;
      &.disabled {
        opacity: 0.5;
        * {
          cursor: not-allowed;
        }
      }
      .details {
        height: 6em;
        div {
          margin-bottom: 0.4rem;
        }
        .label {
          @extend %font-bold;
        }
        .error-message {
          color: red;
        }
      }
      .grid {
        /*max-height: calc(100% - 250px);*/
        overflow: auto;
        margin: 3.2rem 0;
        display: grid;
        width: 100%;
        margin-top: 4rem;
        grid-template-columns: 70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        .cell {
          height: 4.8rem;
          @include flex(center, flex-start);
          position: sticky;
          top: 0;
          outline: none;
          &:not(.header) {
            border-bottom: 1px solid $light-grey;
          }
          &:nth-last-child(-n + 8) {
            border: none;
          }

          input {
            border: transparent;
            width: 80%;
            background: none;
            outline: none;
            height: 3.2rem;
            &.active-line {
              border-bottom: 1px solid $light-grey;
            }
            &:focus {
              border-bottom: 2px solid $purple;
            }
            &.error,
            &:not(:valid) {
              border-bottom: 2px solid red;
            }
          }

          .error-message {
            position: absolute;
            font-size: 13px;
            bottom: -1em;
            width: auto;
          }
        }
        .header {
          @extend %font-bold;
          z-index: 2;
          font-size: 2rem;
          background-color: $background;
        }
      }
    }
  }

  .locked-entry {
    font-size: 16px;
    font-weight: bold;
  }

  .extourne {
    display: flex;
    align-items: center;
  }

  .link-super {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-INVENTAIRE_SUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-RELAIS_CLOTURE_SUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-MAG_SUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-RPO_SUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-RELAIS_CLOTURE_SUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
  .link-LOGSUPER {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 6.4rem;
    width: 98%;
    border-radius: 16px;
    margin-bottom: 2rem;
    background-color: $white;
    outline: none;
    border: 1px solid $dark-grey;
  }
</style>
